ol,
ul {
    list-style: none;
}
a:link,
a:visited {
    color: blue;
    text-decoration: none;
}
a:hover,
a:active {
    color: blue;
    text-decoration: underline;
}
.grid-page {
    height: 100%;
    display: grid;
    grid-gap: 3px;
    grid-template-columns: repeat(12, 1fr);
    mid-width: 0;
}
.top-bar {
    grid-column: 1 / -1;
    padding: 15px;
    justify-content: center;
}
.top-bar-2col {
    display: flex;
    justify-content: space-between;
}
.main_logo {
    margin-right: auto;
}
@media only screen and (max-width: 680px) {
    .top-bar {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}
.top-bar-section {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 680px) {
    .top-bar-section > li {
        flex-basis: 100%;
        flex: 1;
    }
}

.top-bar-section li {
    padding: 10px 10px 0 10px;
}

@media only screen and (max-width: 680px) {
    .top-bar-section {
        display: flex;
        flex-direction: column;
    }
}
.nav-links > a {
    color: #494c4e;
    font-size: 1em;
    font-weight: bolder;
}
.about-me-short {
    grid-column: 3 / -3;
    justify-self: center;
    font-size: 1.5em;
    text-align: center;
}
.icons-me {
    grid-column: 1 / -1;
    justify-self: center;
    padding-bottom: 20px;
}
.icons-me > a {
    padding: 0 20px 0 20px;
    text-decoration: none;
}
a.github-icon {
    color: black;
}
a.twitter-icon {
    color: #1da1f2;
}
a.linkedin-icon {
    color: #0077b5;
}
.techStack {
    font-family: courier;
    font-weight: bolder;
}
.projects-title {
    grid-column: 1 / -1;
    justify-self: center;
    border-bottom: 1px dashed gray;
    text-align: center;
    padding-bottom: 5px;
    width: 30%;
}
.projects {
    grid-column: 1 / -1;
    display: flex;
    flex-wrap: wrap;
    justify-self: center;
    align-content: center;
    justify-content: center;
}
@media only screen and (min-width: 900px) {
    .projects {
        width: 1000px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-self: center;
        text-align: center;
    }
}
.project-box img {
    border-radius: 10px;
}
.image {
    opacity: 1;
}
.container {
    position: relative;
    padding: 5px;
}
.middle {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.mytext {
    padding: 5px;
    background-color: #3b5998;
    color: #ffffff;
    border-radius: 5px;
}
.container:hover .image {
    opacity: 0.3;
}
.container:hover .middle {
    opacity: 1;
}
.projects article {
    flex-basis: 30%;
    padding: 10px;
}
.aboutme {
    grid-column: 3 / -3;
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    justify-self: center;
    font-size: 1.2em;
}
.cert_title {
    border-left: 5px solid black;
    padding: 2px;
    font-family: Georgia;
    font-weight: bold;
    font-size: 1.1em;
}
.cert_ul > li {
    padding: 5px 2px 5px 3px;
}
.certifications {
    grid-column: 1 / -1;
    justify-self: center;
    display: flex;
    font-size: 1.4em;
    font-weight: bold;
}
.list-certifications {
    grid-column: 3 / -3;
    justify-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.cert-rowOne {
    display: flex;
    flex-direction: column;
}
.cert-rowTwo {
    display: flex;
    flex-direction: column;
}
.cert-rowThree {
    display: flex;
    flex-direction: column;
}
@media only screen and (min-width: 700px) {
    .cert-rowOne {
        grid-column: 3/ -3;
        display: flex;
        flex-direction: row;
    }
    .cert-rowTwo {
        grid-column: 3/ -3;
        display: flex;
        flex-direction: row;
    }
    .cert-rowThree {
        grid-column: 3/ -3;
        display: flex;
        flex-direction: row;
    }
}
.cert-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.footer {
    margin: 30px;
    grid-column: 1 / -1;
    font-size: 2em;
    justify-self: center;
}
